import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';

const IndexPage = () => (
  <Layout>
    {/* <h1>Région Sud - Foncier économique</h1> */}
    <ul>
      <li>
        <Link to="/espaces-d-activites/">
          Lister les espaces d'activités
        </Link>
      </li>
      {/* <li>
        Liste des établissements publics de coopération intercommunale (EPCI) de
        la région.
      </li> */}
      <li>
        <Link to="/explorer">
          Explorer/Filtrer les espaces d'activités
        </Link>
      </li>
    </ul>

  </Layout>
);

export default IndexPage;
